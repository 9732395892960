const focusableElements = (target) => {
  return target.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
}

// Lightbox functionality
export const initLightbox = () => {

  const body = document.querySelector("body");
  const lightbox = document.getElementById('ds-lightbox');
  const lightboxContent = document.getElementById('ds-lightbox-content');

  const lightboxPrevious = document.getElementById('ds-lightbox-control-previous');
  const lightboxNext = document.getElementById('ds-lightbox-control-next');
  const lightboxClose = document.getElementById('ds-lightbox-control-close');
  const lightboxCaption = document.getElementById('ds-lightbox-caption');
  const lightboxTotal = document.getElementById('ds-lightbox-total');

  const lightboxLinks = document.querySelectorAll("a.ds-lightbox__link, button.ds-lightbox__link");
  const lightboxGalleries = {};

  const lightboxSettings = {
    timing: 250,
    currentGallery: 'default',
    current: -1,
    open: false,
    touch: {
      startPos: 0,
      lastPos: 0,
      direction: 0,
      moveThreshold: 50,
    },
    lastKeyboardControl: false,
    isTouchDevice: ('ontouchstart' in window),
  };

  // Open the lightbox
  const openLightbox = () => {
    const galleryTotal = lightboxGalleries[lightboxSettings.currentGallery].images.length;
    const galleryTitle = lightboxGalleries[lightboxSettings.currentGallery].title;
    const galleryRef = lightboxGalleries[lightboxSettings.currentGallery].ref;
    const galleryContainer = document.getElementById(`gallery-${galleryRef}`);
    let galleryDescription = `An untitled overlaid lightbox gallery of ${galleryTotal} image${galleryTotal != 1 ? 's' : ''}.`
    if (galleryTitle !== 'untitled') {
      galleryDescription = `An overlaid lightbox gallery, titled ${galleryTitle}, containing ${galleryTotal} image${galleryTotal != 1 ? 's' : ''}.`;
    }
    // Update aria label
    lightbox.setAttribute('aria-label', galleryDescription);
    lightbox.classList.remove('ds-lightbox--closed');
    lightbox.setAttribute("aria-hidden", false);
    body.classList.add("ds-basic__disablescroll");
    lightboxSettings.open = true;
  }

  // Close the lightbox
  const closeLightbox = () => {
    if (window.initialFocus) {
      window.initialFocus.focus();
    }
    lightbox.classList.add('ds-lightbox--closed');
    lightbox.setAttribute("aria-hidden", true);
    body.classList.remove("ds-basic__disablescroll");
    lightboxSettings.current = -1;
    lightboxSettings.open = false;
    lightboxTotal.innerText = ``;
    lightboxSettings.lastKeyboardControl = false;
  }

  // Render the navigational controls
  const renderControls = () => {
    if (isPrevious()) {
      lightboxPrevious.classList.remove('ds-lightbox__control--disabled');
      lightboxPrevious.disabled = false;
    } else {
      lightboxPrevious.classList.add('ds-lightbox__control--disabled');
      lightboxPrevious.disabled = true;
    }
    if (isNext()) {
      lightboxNext.classList.remove('ds-lightbox__control--disabled');
      lightboxNext.disabled = false;
    } else {
      lightboxNext.classList.add('ds-lightbox__control--disabled');
      lightboxNext.disabled = true;
    }
    if (lightboxSettings.lastKeyboardControl === 'right') {
      if (!lightboxNext.disabled) {
        lightboxNext.focus();
      } else {
        lightbox.focus();
      }
    }
    if (lightboxSettings.lastKeyboardControl === 'left') {
      if (!lightboxPrevious.disabled) {
        lightboxPrevious.focus();
      } else {
        lightbox.focus();
      }
    }
  }

  // Load the previous image
  const loadPreviousImage = () => {
    lightboxSettings.touch.direction = -1;
    loadLightboxImage(lightboxSettings.current - (isPrevious() ? 1 : 0));
  }

  // Load the next image
  const loadNextImage = () => {
    lightboxSettings.touch.direction = 1;
    loadLightboxImage(lightboxSettings.current + (isNext() ? 1 : 0));
  }

  // Is there a valid previous image?
  const isPrevious = () => {
    return lightboxSettings.current > 0;
  }

  // Is there a valid next image?
  const isNext = () => {
    return lightboxSettings.current < (lightboxGalleries[lightboxSettings.currentGallery].images.length - 1);
  }

  const lightboxImageSourceTemplate = (srcset, mime) => {
    return `<source
    type="${mime}"
    data-sizes="
    (min-width: 1200px) 96vw,
    (min-width: 900px) 96vw,
    (min-width: 600px) 94vw,
    90vw"
    data-srcset="${srcset.map((s) => {
      if (s.image !== false || s.size !== false) {
        return `${s.image !== false ? s.image : ''} ${s.size !== false ? s.size : ''}
        `;
      }
    })}" />`;
  }

  // HTML template for a lightbox image
  const lightboxImageTemplate = (i) => {
    const { tiny, image, orientation, title, srcset } = lightboxGalleries[lightboxSettings.currentGallery].images[i];

    return `
      <picture class="ds-image__picture ds-lightbox__picture ds-lightbox__picture--${orientation}">
        ${srcset.map((source) => {
      return lightboxImageSourceTemplate(source.srcset, source.mime)
    })}
        <img
          src="${tiny}"
          alt="${title}"
          class="ds-image ds-image--${orientation} ds-lightbox__image ds-u-lazy"
          loading="lazy"
          data-src="${tiny}" />
      </picture>
    `;
  }

  const lightboxPermalinkTemplate = (link) => {
    if (link !== null) {
      return `<br/><a href="${link}" class="ds-button ds-button--small ds-button--inward ds-lightbox__caption-button" tabindex="0"><span class="ds-button__layout ds-u-focuscapture" tabindex="-1">Permalink</span></a>`;
    }
  }

  // Load an image from the lightbox images array
  const loadLightboxImage = (i) => {
    // Check this is a valid image to load
    const galleryTotal = lightboxGalleries[lightboxSettings.currentGallery].images.length;
    const isValid = i >= 0 && i <= (galleryTotal - 1) && galleryTotal > 0;
    if (isValid) {
      if (!lightboxSettings.open) {
        openLightbox();
      }
      if (lightboxSettings.current !== i) {
        lightboxSettings.current = i;
        renderControls();
        addEndTransition();
        window.setTimeout(() => {
          resetImagePosition();
          const newThumbnail = lightboxImageTemplate(i);
          const { image, permalink, title } = lightboxGalleries[lightboxSettings.currentGallery].images[i];
          lightboxContent.innerHTML = newThumbnail;
          lightboxCaption.innerHTML = `${title} ${lightboxPermalinkTemplate(permalink) || ''}`;
          lightboxTotal.innerText = `${lightboxSettings.current + 1}/${galleryTotal}`;
          // Update aria-labels for navigation
          lightboxPrevious.setAttribute('aria-label', `Previous image (${lightboxSettings.current} of ${galleryTotal})`);
          lightboxNext.setAttribute('aria-label', `Next image (${lightboxSettings.current + 2} of ${galleryTotal})`);
          // Load small thumbnail
          const blurImg = new Image();
          blurImg.src = image;
          blurImg.onload = () => {
            window.lazyLoadInstance.update();
          };
        }, lightboxSettings.timing);
      }
    }
  }

  // Reset image position and transitions
  const resetImagePosition = () => {
    setPosition(0);
    clearTransitions();
  }

  // Set position to a given x value
  const setPosition = (x) => {
    lightboxContent.setAttribute('style', `transform: translate3d(${x}px, 0, 0)`);
  }

  // Set end position for transition
  const addEndTransition = () => {
    const endPosition = lightboxSettings.touch.direction > 0 ? '-200%' : lightboxSettings.touch.direction < 0 ? '200%' : 0;
    lightboxContent.setAttribute('style', `transition-duration: ${lightboxSettings.timing}ms; transform: translate3d(${endPosition}, 0, 0)`);
  }

  const parseSrcSet = (srcset) => {
    if (!srcset || !srcset.dataset || !srcset.dataset.srcset) {
      return false;
    }
    const returnSrcset = srcset.dataset.srcset.split(',').map((set) => {
      const setProps = set.trim().split(' ');
      return {
        image: setProps[0] || false,
        size: setProps[1] || false,
      };
    });

    return {
      mime: srcset.type,
      srcset: returnSrcset,
    };
  }

  // Clear CSS transitions
  const clearTransitions = (node) => {
    lightboxContent.setAttribute('style', `transition: none`);
  }

  if (lightboxLinks.length > 0) {

    // Parse lightbox images
    lightboxLinks.forEach((lightboxLink, i) => {

      const galleryRef = lightboxLink.dataset.gallery ? lightboxLink.dataset.gallery : 'default';
      const galleryImageDefault = lightboxLink.querySelector('img');
      const galleryImageSet = lightboxLink.querySelectorAll('source');

      // Set default settings for this gallery
      if (typeof lightboxGalleries[galleryRef] === "undefined") {
        const galleryContainer = document.getElementById(`gallery-${galleryRef}`);
        const galleryTitle = galleryContainer ? galleryContainer.dataset.title : 'untitled';
        lightboxGalleries[galleryRef] = {
          ref: galleryRef,
          title: galleryTitle,
          images: [],
        };
      }

      const srcset = [];
      galleryImageSet.forEach((source) => {
        srcset.push(parseSrcSet(source));
      });

      // Add to lightbox images array for this gallery
      lightboxGalleries[galleryRef].images.push({
        tiny: galleryImageDefault.src,
        image: lightboxLink.href,
        title: lightboxLink.dataset.title,
        permalink: lightboxLink.dataset.permalink || null,
        orientation: lightboxLink.dataset.orientation,
        gallery: galleryRef,
        srcset: srcset,
      });

      const currentIndex = lightboxGalleries[galleryRef].images.length - 1;

      // Add click event to the anchor
      lightboxLink.addEventListener('click', (e) => {
        e.preventDefault();
        lightboxSettings.currentGallery = galleryRef;
        loadLightboxImage(currentIndex);
        window.initialFocus = e.currentTarget;
        lightbox.focus();
      });

    });

    // Keyboard controls
    window.addEventListener('keydown', (e) => {

      if (lightboxSettings.open) {

        const lightboxFocusableElements = focusableElements(lightbox);
        const firstFocusableElement = lightboxFocusableElements[0];
        const altFirstFocusableElement = lightboxFocusableElements[1];
        const lastFocusableElement = lightboxFocusableElements[lightboxFocusableElements.length - 1];
        const altLastFocusableElement = lightboxFocusableElements[lightboxFocusableElements.length - 2];

        const handleBackwardTab = () => {
          if (document.activeElement === firstFocusableElement || (firstFocusableElement.disabled && document.activeElement === altFirstFocusableElement) || (firstFocusableElement.disabled && document.activeElement === lightbox)) {
            e.preventDefault();
            if (!lastFocusableElement.disabled) {
              lastFocusableElement.focus();
            } else {
              altLastFocusableElement.focus();
            }
          }
        }

        const handleForwardTab = () => {
          if (document.activeElement === lastFocusableElement || (lastFocusableElement.disabled && document.activeElement === altLastFocusableElement) || (lastFocusableElement.disabled && document.activeElement === lightbox)) {
            e.preventDefault();
            if (!firstFocusableElement.disabled) {
              firstFocusableElement.focus();
            } else {
              altFirstFocusableElement.focus();
            }
          }
          if (firstFocusableElement.disabled && lastFocusableElement.disabled) {
            e.preventDefault();
            lightboxClose.focus();
          }
        }

        // Trap tab within modal
        if (e.key === 'Tab') {
          if (lightboxFocusableElements.length === 1) {
            e.preventDefault();
          }

          if (e.shiftKey) {
            handleBackwardTab();
          } else {
            handleForwardTab();
          }
        }

        if (e.keyCode === 32 || e.key === 'Enter') {
          if (document.activeElement === lightboxPrevious) {
            lightboxSettings.lastKeyboardControl = 'ArrowLeft';
          }
          if (document.activeElement === lightboxNext) {
            lightboxSettings.lastKeyboardControl = 'ArrowRight';
          }
        }

        if (e.key === 'Escape') {
          closeLightbox();
        }
        if (e.key === 'ArrowLeft') {
          lightboxSettings.lastKeyboardControl = 'ArrowLeft';
          loadPreviousImage();
        }
        if (e.key === 'ArrowRight') {
          lightboxSettings.lastKeyboardControl = 'ArrowRight';
          loadNextImage();
        }
      }

    });

    // Click outside the lightbox content to close
    lightbox.addEventListener('click', (e) => {
      if (e.target.id === "ds-lightbox") {
        e.preventDefault();
        closeLightbox();
      }
    });

    // Previous button
    lightboxPrevious.addEventListener('click', (e) => {
      e.preventDefault();
      loadPreviousImage();
    });

    // Next button
    lightboxNext.addEventListener('click', (e) => {
      e.preventDefault();
      loadNextImage();
    });

    // Close button
    lightboxClose.addEventListener('click', (e) => {
      e.preventDefault();
      closeLightbox();
    });

    // Touch movement event
    lightboxContent.addEventListener('touchmove', (e) => {
      e.preventDefault();
      if (lightboxSettings.touch.lastPos > lightboxSettings.touch.startPos) {
        lightboxSettings.touch.direction = -1;
      } else {
        lightboxSettings.touch.direction = 1;
      }

      setPosition(e.touches[0].clientX - lightboxSettings.touch.startPos);

      if (lightboxSettings.touch.direction !== 0) {
        if (lightboxSettings.touch.direction < 0) {
          setPosition((e.touches[0].clientX - lightboxSettings.touch.startPos)/* - boundingBox[0]*/)
        } else if (lightboxSettings.touch.direction > 0) {
          setPosition((e.touches[0].clientX - lightboxSettings.touch.startPos)/* + boundingBox[0]*/);
        }
      }

      lightboxSettings.touch.lastPos = e.touches[0].clientX;
    });

    // Touch start event
    lightboxContent.addEventListener('touchstart', (e) => {
      lightboxSettings.touch.startPos = e.touches[0].clientX;
      lightboxSettings.touch.direction = 0;
      clearTransitions();
    });

    // Touch end event
    lightboxContent.addEventListener('touchend', (e) => {
      if (lightboxSettings.touch.lastPos - lightboxSettings.touch.startPos > lightboxSettings.touch.moveThreshold) {
        if (isPrevious()) {
          loadPreviousImage();
        } else {
          setPosition(0);
        }
      }
      if (lightboxSettings.touch.lastPos - lightboxSettings.touch.startPos < -lightboxSettings.touch.moveThreshold) {
        if (isNext()) {
          loadNextImage();
        } else {
          setPosition(0);
        }
      }
    });

  }

}