import '../css/styles.css'; // Required for HMR
// import images from '../templates/images.twig';

import { initLightbox } from './features/lightbox.js';

// Vendor
import LazyLoad from "../../node_modules/vanilla-lazyload";

// Dodeca Studio Header Menu
function headerMenu() {

  const menu = document.querySelector(".ds-header__nav");
  const menuToggle = document.getElementById("ds-menu-toggle");
  const menuToggleCheckbox = document.getElementById("ds-menu");
  const body = document.querySelector("body");

  if (menuToggleCheckbox !== null && typeof menuToggleCheckbox === 'object') {
    menuToggleCheckbox.addEventListener("change", function (event) {

      const menuOpen = menu.classList.contains("ds-header__nav--active");
      const newMenuOpenStatus = !menuOpen;

      menu.classList.toggle("ds-header__nav--active");
      menuToggle.setAttribute("aria-expanded", newMenuOpenStatus);

      // Tell body menu is open to prevent scrolling on mobile
      if (body !== null) {
        body.classList.toggle("ds-body--menuopen");
      }
    });
  }

}

headerMenu();
initLightbox();

window.lazyLoadInstance = new LazyLoad({
  elements_selector: '.ds-u-lazy',
  class_applied: 'ds-u-lazy--applied',
  class_loading: 'ds-u-lazy--loading',
  class_loaded: 'ds-u-lazy--loaded',
  class_error: 'ds-u-lazy--error',
  cancel_on_exit: false,
});